const Common_Down = {
    /**
     * 调用浏览器输出文件[下载文件]
     * @param content 文件内容
     * @param filename 文件名
     */
    downLoad(content,filename){
        var ele = document.createElement('a');// 创建下载链接
        ele.download = filename;//设置下载的名称
        ele.style.display = 'none';// 隐藏的可下载链接
        // 字符内容转变成blob地址
        var blob = new Blob([content]);
        ele.href = URL.createObjectURL(blob);
        // 绑定点击时间
        document.body.appendChild(ele);
        ele.click();
        // 然后移除
        document.body.removeChild(ele);
    },
};
export default Common_Down;