import {decode, encode} from "js-base64";

const Common_Base64 = {
    /**
     * 加密
     * @param data
     * @return {string}
     */
    en(data){
        return encode(data)
    },

    /**
     * 解密
     * @param data
     * @return {string}
     */
    de(data){
        return decode(data);
    }
};
export default Common_Base64;